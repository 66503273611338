// This method stops the Pace progress bar when it is at 99% for more than 4 seconds
// This is a fix for IE 11, where the progress bar would stay at 99% forever without finishing.
// bug: http://interflex-tfs.allegion.com:8080/tfs/DefaultCollection/IF6040.2/_workitems?id=202782&_a=edit
// solution idea: https://stackoverflow.com/questions/43887415/pace-js-never-reaches-100
var stopPaceAfterInterval = function() {
    var counter = 0;
    var iterations = 0;

    var refreshIntervalId = setInterval( function(){
        var progress;

        // get the current progress value (unfortunately there is no better, elegant way to do that)
        if( typeof $( '.pace-progress' ).attr( 'data-progress-text' ) !== 'undefined' ) {
            progress = Number( $( '.pace-progress' ).attr( 'data-progress-text' ).replace('%', '') );
        }

        if( progress === 99 ) {
            counter++;
        }

        if( counter > 20 ) {
            clearInterval(refreshIntervalId);
            Pace.stop();
            document.body.className = document.body.className.replace('pace-running', '');
        }

        // stop after 150 iterations (equals 30 seconds), so the script doesn't run forever
        if (iterations > 150) {
            clearInterval(refreshIntervalId);
        }

        iterations++;
    }, 200);
};
stopPaceAfterInterval();