/**
 * @author: Max Reinhardt
 * Created on: 05.12.2017
 * this code was developed for fullcalendar.io - v3.1.0
 * IMPORTANT: changes in this file require to run the 'vendor' - build process
 */
(function () {
  'use strict';

  $.fullCalendar.DayGrid.mixin({
    /*
    // Renders an <a> element that represents hidden event element for a cell.
    // Responsible for attaching click handler as well.
    renderMoreLink: function (row, col, hiddenSegs) {
      var _this = this;
      var view = this.view;

      return $('<a class="fc-more"/>')
        .text(
          this.getMoreLinkText(hiddenSegs.length)
        )
        //todo <MODIFICATION>: add 'mouseover' event to event listener
        .on('click mouseover', function (ev) {//mouseover
          var clickOption = view.opt('eventLimitClick');
          var date = _this.getCellDate(row, col);
          var moreEl = $(this);
          var dayEl = _this.getCellEl(row, col);
          var allSegs = _this.getCellSegs(row, col);

          // rescope the segments to be within the cell's date
          var reslicedAllSegs = _this.resliceDaySegs(allSegs, date);
          var reslicedHiddenSegs = _this.resliceDaySegs(hiddenSegs, date);

          if (typeof clickOption === 'function') {
            // the returned value can be an atomic option
            clickOption = view.publiclyTrigger('eventLimitClick', null, {
              date: date,
              dayEl: dayEl,
              moreEl: moreEl,
              segs: reslicedAllSegs,
              hiddenSegs: reslicedHiddenSegs
            }, ev);
          }

          if (clickOption === 'popover') {
            _this.showSegPopover(row, col, moreEl, reslicedAllSegs);
          }
          else if (typeof clickOption === 'string') { // a view name
            view.calendar.zoomTo(date, clickOption);
          }
        });
    },
    */

    // Limits the given grid row to the maximum number of levels and injects "more" links if necessary.
    // `row` is the row number.
    // `levelLimit` is a number for the maximum (inclusive) number of levels allowed.
    limitRow: function (row, levelLimit) {
      var _this = this;
      var rowStruct = this.rowStructs[row];
      var moreNodes = []; // array of "more" <a> links and <td> DOM nodes
      var col = 0; // col #, left-to-right (not chronologically)
      var levelSegs; // array of segment objects in the last allowable level, ordered left-to-right
      var cellMatrix; // a matrix (by level, then column) of all <td> jQuery elements in the row
      var limitedNodes; // array of temporarily hidden level <tr> and segment <td> DOM nodes
      var i, seg;
      var segsBelow; // array of segment objects below `seg` in the current `col`
      var totalSegsBelow; // total number of segments below `seg` in any of the columns `seg` occupies
      var colSegsBelow; // array of segment arrays, below seg, one for each column (offset from segs's first column)
      var td, rowspan;
      var segMoreNodes; // array of "more" <td> cells that will stand-in for the current seg's cell
      var j;
      var moreTd, moreWrap, moreLink;

      // Iterates through empty level cells and places "more" links inside if need be
      function emptyCellsUntil(endCol) { // goes from current `col` to `endCol`
        while (col < endCol) {
          segsBelow = _this.getCellSegs(row, col, levelLimit);
          if (segsBelow.length) {
            td = cellMatrix[levelLimit - 1][col];
            moreLink = _this.renderMoreLink(row, col, segsBelow);
            moreWrap = $('<div/>').append(moreLink);
            td.append(moreWrap);
            moreNodes.push(moreWrap[0]);
          }
          col++;
        }
      }

      if (levelLimit && levelLimit <= rowStruct.segLevels.length) { // is it actually over the limit?
        levelSegs = rowStruct.segLevels[levelLimit - 1];
        cellMatrix = rowStruct.cellMatrix;

        limitedNodes = rowStruct.tbodyEl.children().slice(levelLimit) // get level <tr> elements past the limit
          .addClass('fc-limited').get(); // hide elements and get a simple DOM-nodes array

        // iterate though segments in the last allowable level
        for (i = 0; i < levelSegs.length; i++) {
          seg = levelSegs[i];
          emptyCellsUntil(seg.leftCol); // process empty cells before the segment

          // determine *all* segments below `seg` that occupy the same columns
          colSegsBelow = [];
          totalSegsBelow = 0;
          //todo <MODIFICATION>: replace "=<" with "<" in while loop condition
          while (col < seg.rightCol) {
            segsBelow = this.getCellSegs(row, col, levelLimit);
            colSegsBelow.push(segsBelow);
            totalSegsBelow += segsBelow.length;
            col++;
          }

          if (totalSegsBelow) { // do we need to replace this segment with one or many "more" links?
            td = cellMatrix[levelLimit - 1][seg.leftCol]; // the segment's parent cell
            rowspan = td.attr('rowspan') || 1;
            segMoreNodes = [];

            // make a replacement <td> for each column the segment occupies. will be one for each colspan
            for (j = 0; j < colSegsBelow.length; j++) {
              moreTd = $('<td class="fc-more-cell"/>').attr('rowspan', rowspan);
              segsBelow = colSegsBelow[j];
              moreLink = this.renderMoreLink(
                row,
                seg.leftCol + j,
                [seg].concat(segsBelow) // count seg as hidden too
              );
              moreWrap = $('<div/>').append(moreLink);
              moreTd.append(moreWrap);
              segMoreNodes.push(moreTd[0]);
              moreNodes.push(moreTd[0]);
            }

            td.addClass('fc-limited').after($(segMoreNodes)); // hide original <td> and inject replacements
            limitedNodes.push(td[0]);
          }
        }

        emptyCellsUntil(this.colCnt); // finish off the level
        rowStruct.moreEls = $(moreNodes); // for easy undoing later
        rowStruct.limitedEls = $(limitedNodes); // for easy undoing later
      }
    }
  });

})();
